import React, { useState, useEffect } from "react"

import "../styles/bootstrap.min.css"
import "./profileView.css"
import SEO from "../components/seo"

const url = 'https://api.plaiz.io';

const ProfileViewPage = () => {
    const [ user, setUser ] = useState(null);
    const [ posts, setPosts ] = useState([]);

    useEffect(() => {
      loadProfile();
      loadPosts();
    }, []);

    const loadProfile = async () => {
      try {
        const profileId = window.location.href.split('=').slice(-1)[0];
        const res = await fetch(`${url}/api/users/${profileId}`);
        const data = await res.json();
        setUser(data);
      } catch (err) {
        console.error('Error loading profile', err);
      }
    }

    const loadPosts = async () => {
      try {
        const profileId = window.location.href.split('=').slice(-1)[0];
        const res = await fetch(`${url}/api/posts/user/${profileId}`);
        const data = await res.json();
        setPosts(data);
      } catch (err) {
        console.error('Error loading posts', err);
      }
    }

    return (
      <div className="profile-page">
          <SEO title="Plaiz - Le Réseau Social De La Mode - Profile"/>
          <div className="profile-container">
              {!!user && <img className="big-profile-pic" src={user.profile_pic}/>}
              <div className="profile-info">
                  {!!user && <p className="profile-username">{user.username}</p>}
                  {!!user && <p className="profile-bio">{user.bio}</p>}
              </div>
              <div className="big-profile-pic-gradient"/>
              {posts.length > 0 && <div className="profile-posts-container">
                {posts.map(p => (
                  <img key={p.picture} className="profile-thumbnail-image" src={`https://api.plaiz.io/images/${p.picture}`}/>
                ))}
              </div>}
              <p className="footer-text-profile-view">
                  A propos: Plaiz est un anti-réseau social pour les amateurs et passionnés de mode, sur lequel on est tous la version la plus audacieuse de nous-mêmes. On met en avant la créativité plutôt que la popularité, donc chez nous, pas de compteurs de likes, pas de followers, pas de prise de tête, pas de jugement.
              </p>
          </div>
          <a target="_blank" href="https://plaiz.onelink.me/kHUQ/profilelink" className="download-button-profile">
              <p className="download-text">Télécharger Plaiz 🌹</p>
          </a>
      </div>
    )
}

export default ProfileViewPage
